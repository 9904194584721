/**
 * A class to help work with timezones for times obtained using the Yext API.
 * Assumes that Yext Services was used and that the model is a YextStoreModel.
 */
export default class TimeZone {

    /**
     * Find the store time zone UTC offset and splits it on the colon.
     * @param yextStoreModel A YextStoreModel
     * @returns A string array containing the time zone UTC offset.
     */
    static getStoreTimeZoneUtcOffsetFromYext(yextStoreModel: unknown): string[] {
        return ((yextStoreModel as any).getTimeZoneUtcOffset() as string).split(":");
    }

    /**
     * Find the store time zone UTC offset and splits it on the colon.
     * @param storeHours The store hours previously extracted out of a Yext Store Model. Assumes that it is JSON data that has been parsed.
     * @returns A string array containing the time zone UTC offset.
     */
    static getStoreTimeZoneUtcOffsetFromStoreHours(storeHours: Record<string, any>): string[] {
        return storeHours.timeZoneUtcOffset.split(":");
    }

    /**
     * Converts the store time from a given UTC offset to the current time on the user's device.
     * @param storeTimeZoneUtcOffset The store time zone in UTC.
     * @returns The current time.
     */
    static getCurrentTime(storeTimeZoneUtcOffset: string[]): Date {
        // convert hours offset to minutes and flip the sign in order to align with how Date accounts for offset from UTC
        const storeHoursOffset = Number(storeTimeZoneUtcOffset[0]) * 60 * -1;
        let storeMinutesOffset = Number(storeTimeZoneUtcOffset[1]);

        if (Number(storeTimeZoneUtcOffset[0]) >= 0) {
            storeMinutesOffset *= -1;
        }
        const storeTimeOffset = storeHoursOffset + storeMinutesOffset;

        const currentTime = new Date();
        const localTimezoneOffset = currentTime.getTimezoneOffset();

        // find difference in offset between store time and local time
        const offset = storeTimeOffset - localTimezoneOffset;

        // use offset to find time right now at store location
        currentTime.setTime(currentTime.getTime() - (offset * 60 * 1000));

        return currentTime;
    }

    /**
     * Get the hours for which the store is open for the *current* day.
     * @param currentTime The current time, obtained from [[getCurrentTime]].
     * @param storeHours The store hours, obtained from the `YextStoreModel.getHours()`.
     * @returns The store hours for the current day, encapsulated as an object. Note, the structure is derived from `YextStoreModel`.
     */
    static getOpenHoursForDay(currentTime: Date, storeHours: Record<string, unknown>): unknown {
        const currentDay = currentTime.getDay().toString();

        const storeDay: Record<string, unknown> = {
            0: storeHours.sunday,
            1: storeHours.monday,
            2: storeHours.tuesday,
            3: storeHours.wednesday,
            4: storeHours.thursday,
            5: storeHours.friday,
            6: storeHours.saturday
        };

        const storeHoursIndex = (Object.keys(storeDay).filter(day => day === currentDay).pop()) as string;
        const openHours = storeDay[storeHoursIndex];

        return openHours;
    }

    /**
     * Get the open/close time details for the current day.
     * @param currentTime The current time, obtained from [[getCurrentTime]].
     * @param openHours The open hours for the day, obtained from [[getOpenHoursForDay]].
     * @returns A tuple containing the open/closed status, the open till hour, and open till minutes in the form:
     *
     * ```typescript
     * <isOpen: Boolean, openTillHours: Number, openTillMinutes: Number>
     * ```
     *
     * `openTillHours` is a `Number` such that `0 <= openTillHours <= 24`.
     * `openTillMinutes` is a `Number` such that `0 <= openTillMinutes < 60`.
     */
    static getOpenTimeDetails(currentTime: Date, openHours: unknown): (boolean | number)[] {

        let isOpen = false;
        let openTillHours = -1;
        let openTillMinutes = -1;

        for (const openInterval of (openHours as any).openIntervals as Record<string, string>[]) {
            const startTime = (openInterval.start).split(":");
            const startHours = Number(startTime[0]);
            const startMinutes = Number(startTime[1]);
            const start = startHours * 60 + startMinutes;
            // console.log("Timezone.getOpenTimeDetails(): start:", start);

            const endTime = (openInterval.end).split(":");
            const endHours = Number(endTime[0]);
            const endMinutes = Number(endTime[1]);
            const end = endHours * 60 + endMinutes;
            // console.log("Timezone.getOpenTimeDetails(): end:", end);

            const currentHours = currentTime.getHours();
            const currentMinutes = currentTime.getMinutes();
            const now = currentHours * 60 + currentMinutes;

            // console.log("Timezone.getOpenTimeDetails(): now:", now);

            if (now >= start && now <= end) {
                isOpen = true;
                openTillHours = endHours;
                openTillMinutes = endMinutes;

                // console.log("Timezone.getOpenTimeDetails(): endHours:", endHours);
                // console.log("Timezone.getOpenTimeDetails(): endMinutes:", endMinutes);
                break;
            }
        }

        return [isOpen, openTillHours, openTillMinutes];
    }

    /**
     * Get the time the store is open until.
     * @param openTimeDetails The open time details obtained from [[getOpenTimeDetails]].
     * @returns A string in the form of `HH:MM a.m.` or `HH:MM p.m.`.
     */
    static getOpenTillText(openTimeDetails: (boolean | number)[]): string {
        const isOpen = openTimeDetails[0] as boolean;
        let openTill: string;

        if (isOpen) {
            let openTillHours = openTimeDetails[1] as number;
            const openTillMinutes = openTimeDetails[2] as number;

            let isPM = false;

            if (openTillHours > 12) {
                isPM = true;
                openTillHours = openTillHours - 12;
            }

            openTill = "Open till " + openTillHours.toString() + ":";
            openTill += openTillMinutes.toString().padStart(2, "0") + " ";
            openTill += isPM ? "p.m." : "a.m.";

        } else {
            openTill = "Currently Closed";
        }

        return openTill;
    }

    /**
     * Get the time the store is open until.
     * @param storeHours The store hours previously extracted out of a Yext Store Model. Assumes that it is JSON data that has been parsed.
     * @returns A string in the form of `HH:MM a.m.` or `HH:MM p.m.`.
     */
    static getOpenTillTextFromStoreHours(storeHours: string): string {
        const storeHoursJSON = JSON.parse(storeHours) as Record<string, any>;
        const storeTimeZoneUtcOffset = this.getStoreTimeZoneUtcOffsetFromStoreHours(storeHoursJSON);
        const currentTime = this.getCurrentTime(storeTimeZoneUtcOffset);
        const openHoursForDay = this.getOpenHoursForDay(currentTime, storeHoursJSON);
        const openTimeDetails = this.getOpenTimeDetails(currentTime, openHoursForDay);
        const openTillText = this.getOpenTillText(openTimeDetails);

        return openTillText;
    }

    /**
     * Sets the HTML element containing the CSS dot to be green if open and red if closed.
     * @param dotEl HTML element containing the dot.
     * @param isOpen Boolean flag whether the store is open or not.
     */
    static setDotElColor(dotEl: HTMLElement, isOpen: boolean): void {
        if (isOpen) {
            dotEl.classList.add("green-dot");
        } else {
            dotEl.classList.add("red-dot");
        }
    }

    /**
     * Set the open time details to the HTML element to be displayed on the page.
     * @param textEl HTML element to set the open / closed text.
     * @param openTimeDetails The open time details obtained from [[getOpenTimeDetails]].
     */
    static setOpenCloseText(textEl: HTMLElement, openTimeDetails: (number | boolean)[]): void {
        const isOpen = openTimeDetails[0] as boolean;
        if (isOpen) {
            textEl.innerHTML = this.getOpenTillText(openTimeDetails);
        } else {
            textEl.innerHTML = "Currently Closed";
        }
    }
}
